import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../templates/Layout";

import * as styles from "./session-calendar.module.css"


export const pageQuery = graphql`
    query Calendar {
        mdx(slug: {eq: "session-calendar"}) {
            body
            frontmatter {
              title
            }
        }
    }
`


class Calendar extends React.Component {
    render() {
        const data = this.props.data.mdx;

        return (
            <Layout
                title={data.frontmatter.title}
                location={this.props.location}
            >
                <h1>{data.frontmatter.title}</h1>
                <p>{data.frontmatter.summary}</p>
                <MDXRenderer>{data.body}</MDXRenderer>
                <div className="iFrameWrapper">
                    <iframe width="800" height="600" title="session calendar" src="https://www.google.com/calendar/embed?src=j416qvi3tacb2e0pkbbbic3m84%40group.calendar.google.com&amp;ctz=Europe%2FLondon" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                </div>
            </Layout>
        )
    }
}
        
export default Calendar

